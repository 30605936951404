import { cn } from "@/utils";

type TextProps = {
  children: React.ReactNode;
} & React.HTMLProps<HTMLParagraphElement>;

export const H1 = ({ children, className, ...props }: TextProps) => (
  <h1 className={cn(`text-6xl font-bold text-primary`, className)} {...props}>
    {children}
  </h1>
);

export const H2 = ({ children, className, ...props }: TextProps) => (
  <h2
    className={cn(
      `text-2xl font-semibold text-primary md:text-3xl lg:text-5xl`,
      className,
    )}
    {...props}
  >
    {children}
  </h2>
);

export const H3 = ({ children, className, ...props }: TextProps) => (
  <h3
    className={cn(
      `text-xl font-medium text-primary md:text-2xl lg:text-3xl`,
      className,
    )}
    {...props}
  >
    {children}
  </h3>
);

export const H4 = ({ children, className, style }: TextProps) => (
  <h4
    className={cn(
      `text-lg font-medium text-primary md:text-xl lg:text-2xl`,
      className,
    )}
    style={style}
  >
    {children}
  </h4>
);

export const Paragraph = ({ children, className, style }: TextProps) => (
  <p className={cn(`text-base text-primary`, className)} style={style}>
    {children}
  </p>
);
