"use client";
import { PostSearch } from "@/components/post-search/post-search";
import { ThemeSwitcher } from "@/components/theme/theme-switcher";
import { Container } from "@/components/ui/container";
import { Link } from "@/components/ui/link";
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
} from "@/components/ui/navigation-menu";
import { cn } from "@/utils";
import { Menu } from "lucide-react";
import { usePathname } from "next/navigation";
import { useState } from "react";
import { Button } from "../ui/button";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
} from "../ui/drawer";

export const Navbar = () => {
  const currentRoute = usePathname();
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <div className="border-b border-gray-200 bg-secondary shadow-xl dark:border-gray-600">
      <Container className="flex items-center justify-between p-4">
        <Link href="/" className="text-2xl font-bold">
          <span className="text-indigo-600">M</span>B
        </Link>
        <NavigationMenu>
          <NavigationMenuList>
            <NavigationMenuItem>
              <PostSearch />
            </NavigationMenuItem>
            <NavigationMenuItem className="hidden lg:block">
              <Link
                href="/about"
                className={cn(
                  currentRoute === "/about" &&
                    "text-indigo-600 dark:text-indigo-500",
                )}
              >
                About
              </Link>
            </NavigationMenuItem>
            <NavigationMenuItem className="hidden lg:block">
              <Link
                href="/blog/1"
                className="relative z-[1] border-2 border-indigo-600 bg-transparent px-5 py-2.5 font-medium uppercase text-indigo-600 transition-colors before:absolute before:left-0 before:top-0 before:-z-10 before:h-full before:w-full before:origin-top-left before:scale-y-0 before:bg-indigo-600 before:transition-transform before:duration-300 before:content-[''] hover:text-white before:hover:scale-y-100 dark:hover:text-gray-200"
              >
                {"blog =>"}
              </Link>
            </NavigationMenuItem>
            <NavigationMenuItem>
              <ThemeSwitcher />
            </NavigationMenuItem>
            <NavigationMenuItem className="lg:hidden">
              <Button
                size="icon"
                variant="ghost"
                onClick={() => setDrawerOpen(true)}
                aria-label="Open menu"
              >
                <Menu className="h-[1.2rem] w-[1.2rem]" />
              </Button>
            </NavigationMenuItem>
          </NavigationMenuList>
        </NavigationMenu>
      </Container>
      <Drawer open={drawerOpen} onOpenChange={setDrawerOpen}>
        <DrawerContent>
          <DrawerHeader className="flex justify-center">Menu</DrawerHeader>
          <div className="p-4 pb-2">
            <div className="flex items-center justify-center space-x-2">
              <Link href="/about" className="mr-4">
                <Button
                  variant="secondary"
                  aria-label="go to the about me page"
                >
                  About
                </Button>
              </Link>
              <Link href="/blog/1">
                <Button
                  variant="secondary"
                  aria-label="go to the blog list page"
                >
                  Blog
                </Button>
              </Link>
            </div>
          </div>
          <DrawerFooter>
            <DrawerClose asChild>
              <Button variant="ghost" aria-label="Close drawer">
                Close
              </Button>
            </DrawerClose>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </div>
  );
};
