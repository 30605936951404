import { cn } from "@/utils";
import NextLink, { LinkProps as NextLinkProps } from "next/link";

type LinkProps = {
  children: React.ReactNode;
  className?: string;
  target?: string;
  rel?: string;
} & NextLinkProps;

export const Link = (props: LinkProps) => {
  const { className, children } = props;
  return (
    <NextLink
      {...props}
      className={cn(
        "text-primary hover:text-blue-600 dark:hover:text-blue-500",
        className,
      )}
    >
      {children}
    </NextLink>
  );
};
