"use client";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Paragraph } from "@/components/ui/text";
import useDebounce from "@/hooks/useDebounce";
import { client } from "@/sanity/lib/client";
import { urlForImage } from "@/sanity/lib/image";
import { getCategoriesQuery, getPostsByNameQuery } from "@/sanity/lib/queries";

import { PostCategoryType, PostType } from "@/types/PostTypes";
import { dateUtil } from "@/utils";
import { Search } from "lucide-react";
import Image from "next/image";
import NextLink from "next/link";
import { useState } from "react";
import useSWR from "swr";

async function searchPost(search: string): Promise<PostType[]> {
  return client.fetch(getPostsByNameQuery, { name: search });
}

async function getCategories(): Promise<PostCategoryType[]> {
  return client.fetch(getCategoriesQuery);
}
export const PostSearch = () => {
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const debouncedSearch = useDebounce(search, 500);
  const {
    data: posts,
    error: postsError,
    isLoading: postsIsLoading,
  } = useSWR<PostType[]>(debouncedSearch ? `${debouncedSearch}` : null, () =>
    searchPost(search),
  );
  const closeDialog = () => setIsOpen(false);
  const { data: categories } = useSWR(getCategoriesQuery, getCategories);
  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button
          aria-label="open search post dialog"
          size="icon"
          variant="ghost"
          onClick={() => setIsOpen(true)}
        >
          <Search className="h-[1.2rem] w-[1.2rem] text-primary hover:text-blue-600 dark:hover:text-blue-500" />
        </Button>
      </DialogTrigger>

      <DialogContent className="fixed left-1/2 top-1/4 w-11/12 -translate-x-1/2 -translate-y-1/4 transform md:w-full">
        <DialogHeader>
          <DialogTitle>Search Post</DialogTitle>
        </DialogHeader>
        <div className="overflow-hidden rounded-lg">
          <div>
            <Input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search..."
              isLoading={postsIsLoading}
              error={postsError}
            />
          </div>
          <div className="max-h-60 overflow-auto">
            {!posts?.length ? (
              <ul className="mt-3 flex flex-col">
                <li className="mb-2">
                  <NextLink href="/blog" onClick={closeDialog}>
                    All posts
                  </NextLink>
                </li>
                {categories?.map((category: PostCategoryType) =>
                  category.totalPosts > 0 ? (
                    <li key={category._id} className="mb-2">
                      <NextLink
                        href={`/category/${category.slug}`}
                        onClick={closeDialog}
                      >
                        {category.title} ({category.totalPosts})
                      </NextLink>
                    </li>
                  ) : null,
                )}
              </ul>
            ) : null}
            {posts?.map((post: PostType) => {
              const { publishedAt, slug, _id, image, title } = post;
              return (
                <NextLink
                  href={`/post/${slug}`}
                  key={_id}
                  onClick={closeDialog}
                >
                  <div className="flex cursor-pointer border-b-2 border-indigo-700 p-2">
                    <Image
                      src={urlForImage(image).url()}
                      width={36}
                      height={48}
                      alt={title}
                      className="h-12 w-9 object-cover"
                    />
                    <div className="mx-2 w-full">
                      <Paragraph className="truncate">{title}</Paragraph>
                      {publishedAt && (
                        <div className="text-sm">{dateUtil(publishedAt)}</div>
                      )}
                    </div>
                  </div>
                </NextLink>
              );
            })}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
