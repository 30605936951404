import { groq } from "next-sanity";

export const postQuery = groq`*[_type == "post" && slug.current == $slug][0]{
  _id,
  _createdAt,
  "slug": slug.current,
  "image": image,
  title,
  body[] {
    ...,
    markDefs[] {
      ...,
      _type == "internalLink" => { "href": "/"+ @.reference-> slug.current }
    },
    "_id": asset -> _id,
    "url": asset -> url,
    "mimeType": asset -> mimeType,
    "extension": asset -> extension,
  },
  publishedAt,
  category,
  description,
}`;

export const postsPaginatedQuery = groq`
{
  "posts": *[_type == "post"] | order(publishedAt desc) [$start..$end] {
    _id,
    _createdAt,
    publishedAt,
    title,
    "slug": slug.current,
    "image": {
      "url": image.asset->url,
      "_id": image.asset->_id,
      "asset": image.asset,
      "extension": image.asset->extension,
      "mimeType": image.asset->mimeType,
      "alt": title,
    },
    category,
    description,
    "estimatedReadingTime": round(length(pt::text(body)) / 5 / 180 )
  },
  "total": count(*[_type == "post"])
}`;

export const getPostsByNameQuery = groq`*[_type == "post" && title match $name + "*"]{
  _id,
  _createdAt,
  publishedAt,
  title,
  "slug": slug.current,
  "image": {
    "url": image.asset->url,
    "_id": image.asset->_id,
    "asset": image.asset,
    "extension": image.asset->extension,
    "mimeType": image.asset->mimeType,
    "alt": title,
  },
}`;

export const postPathsQuery = groq`*[_type == "post" && defined(slug.current)][]{
  "slug": slug.current
}`;

export const getAuthorQuery = groq`*[_type == "author"][0]{
  name,
  image,
  bio,
  "image": image.asset->url,
  "experiences": experiences[]->{
    _id,
    _createdAt,
    jobTitle,
    company,
    startDate,
    endDate,
    description,
    isCurrent
  }
}`;

export const getCategoriesQuery = groq`*[_type == "category"]{
  _id,
  _createdAt,
  title,
  description,
  "slug": slug.current,
  "totalPosts": count(*[_type == "post" && references(^._id)])
}`;

export const getPaginatedPostsByCategoryQuery = groq`
{
  "posts": *[_type == "post" && $categorySlug in categories[]->slug.current] | order(publishedAt desc) [$start..$end] {
    _id,
    _createdAt,
    publishedAt,
    title,
    "slug": slug.current,
    "image": {
      "url": image.asset->url,
      "_id": image.asset->_id,
      "asset": image.asset,
      "extension": image.asset->extension,
      "mimeType": image.asset->mimeType,
      "alt": title,
    },
    description,
    "estimatedReadingTime": round(length(pt::text(body)) / 5 / 180)
  },
  "category": *[_type == "category" && slug.current == $categorySlug][0] {
    title,
    "slug": slug.current
  },
  "total": count(*[_type == "post" && $categorySlug in categories[]->slug.current])
}
`;

export const getPostSeoDataQuery = groq`*[_type == "post" && slug.current == $slug][0]{
  title,
  description,
  "image": image.asset->url,
  "slug": slug.current,
  keywords,
  author->{
    name,
    "image": image.asset->url,
  },
}`;

export const getAllPostsQuery = groq`*[_type == "post"]{
  "slug": slug.current,
  "image": image.asset->url,
  "publishedAt": publishedAt,
  "title": title,
  "description": description,
  "categories": categories[]->{
    "slug": slug.current,
    "title": title
  }
}`;
