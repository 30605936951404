import { apiVersion, dataset, projectId } from "@/env";
import { createClient } from "next-sanity";

const isDevelopment = process.env.NODE_ENV === "development";

export const client = createClient({
  apiVersion,
  dataset,
  projectId,
  useCdn: isDevelopment ? false : true,
  perspective: isDevelopment ? "previewDrafts" : "published",
  stega: {
    studioUrl: "/admin",
    filter: (props) => {
      if (props.sourcePath.at(-1) === "title") {
        return true;
      }

      return props.filterDefault(props);
    },
  },
});
