import { cn } from "@/utils";
import { ReactNode } from "react";

type ContainerProps = {
  children: ReactNode;
  className?: string;
};
const classes = {
  base: "container mx-auto px-4 md:px-8 xl:px-24",
};
export const Container = ({ children, className }: ContainerProps) => {
  return <div className={cn(classes.base, className)}>{children}</div>;
};
