import { LoadingIcon } from "@/components/icons/LoadingIcon";
import { cn } from "@/utils";
import { forwardRef, InputHTMLAttributes } from "react";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  placeholder?: string;
  className?: string;
  name?: string;
  error?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  icon?: string;
  isLoading?: boolean;
}

const classes = {
  base: "block w-full px-4 py-3 leading-tight text-gray-700 bg-gray-200 border rounded appearance-none focus:outline-none focus:bg-white",
  error: "border-red-500",
};

export const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      placeholder,
      name,
      onChange,
      error,
      className,
      onKeyDown,
      disabled,
      icon,
      isLoading,
      ...props
    },
    ref,
  ) => {
    return (
      <div className="relative">
        {icon && icon}
        <input
          className={cn(classes.base, error && classes.error, className)}
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          onKeyDown={onKeyDown}
          ref={ref}
          {...props}
        />

        {isLoading && (
          <div className="absolute right-0 top-1.5 mr-2">
            <LoadingIcon className="" />
          </div>
        )}
      </div>
    );
  },
);

Input.displayName = "Input";
