import createImageUrlBuilder from "@sanity/image-url";

import { ImageType } from "@/components/block/ImageBlock";
import { dataset, projectId } from "@/env";

const imageBuilder = createImageUrlBuilder({
  projectId: projectId || "",
  dataset: dataset || "",
});

export const urlForImage = (source: ImageType) => {
  return imageBuilder?.image(source).auto("format").fit("max");
};
